<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-card
      v-if="appInfo.id"
      outlined
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="2"
          class="pa-1"
        >
          <v-img
            :src="appInfo.logo"
            contain
            height="60px"
          />
        </v-col>
        <v-col
          class="pa-0"
        >
          {{ appInfo.name }}
        </v-col>
      </v-row>
    </v-card>
    <div v-html="app.addAccountInfo" />
    <v-select
      tabindex="1"
      v-if="!appInfo.id"
      :items="appList"
      ref="selectedApp"
      item-text="name"
      item-value="id"
      label="Seleziona applicazione"
      return-object
      v-model="app"
    >
      <template #prepend>
        <v-img
          height="40px"
          max-width="40px"
          contain
          :src="app.logo || ''"
        />
      </template>
      <template #item="{ item }">
        <v-list-item-avatar>
          <v-img
            height="40px"
            max-width="40px"
            contain
            :src="item.logo || ''"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.name" />
        </v-list-item-content>
      </template>
    </v-select>
    <v-text-field
      tabindex="2"
      v-if="app.regType.includes('vcode')"
      v-model="registerLocal.validation_code"
      :rules="[rules.requiredIfNot(registerLocal, 'app_login', 'Obbligatorio se non compilato il campo App Login')]"
      label="Codice convalida"
      name="validation_code"
      prepend-icon="mdi-file-code"
      @blur="$refs.form.validate()"
      type="text"
    />
    <template v-if="app.regType.includes('account')">
      <v-text-field
        tabindex="3"
        v-model="registerLocal.app_login"
        :rules="[rules.requiredIfNot(registerLocal, 'validation_code', 'Obbligatorio se NON compilato il campo Codice convalida')]"
        label="App Login"
        name="app_login"
        prepend-icon="mdi-account"
        @blur="$refs.form.validate()"
        type="text"
      />
      <v-text-field
        tabindex="4"
        v-model="registerLocal.app_password"
        :rules="[rules.requiredIf(registerLocal, 'app_login', 'Obbligatorio insieme al campo App Login')]"
        id="app_password"
        label="App password"
        name="app_password"
        prepend-icon="mdi-lock"
        :append-icon="show.app_password ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show.app_password ? 'text' : 'password'"
        counter
        clearable
        @click:append="show.app_password = !show.app_password"
        @blur="$refs.form.validate()"
      />
    </template>
    <p
      class="text-right"
      v-if="app.id && app.hasRecovery"
    >
      <router-link
        to="#"
        @click.native="$emit('doRecovery', app)"
      >
        Recupera Credenziali account {{ app.name }}
      </router-link>
    </p>
    <div v-html="app.addAccountInfo2" />
  </v-form>
</template>
<script>
import rules from '@/utils/rules.js'
// import isEmpty from 'lodash/isEmpty'
// import { regInfoFromToken, regInfoFromClientId, paramsFromNext } from '@/utils/misc.js'
export default {
  props: {
    addAccountInfo: {
      type: String,
      default: ''
    },
    appList: {
      type: Array,
      default: () => ([])
    },
    appInfo: {
      type: Object,
      default: () => ({
        id: null,
        name: '',
        description: '',
        logo: '',
        disclaimer: '',
        registerInfo: '',
        addAccountInfo: '',
        regType: 'account',
        vcode: null,
        username: null
      })
    },
    register: {
      type: Object,
      default: () => ({
        app_login: null,
        app_password: null,
        auth_privacy: true,
        email: null,
        password1: null,
        password2: null,
        username: null,
        validation_code: null
      })
    }
  },
  data () {
    return {
      valid: false,
      rules,
      show: { app_password: false },
      password: null,
      appInfoLocal: {
        id: null,
        name: '',
        description: '',
        logo: '',
        disclaimer: '',
        registerInfo: '',
        addAccountInfo: '',
        addAccountInfo2: '',
        regType: 'account',
        vcode: null,
        username: null
      }
    }
  },
  computed: {
    registerLocal: {
      get () {
        return this.register
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    app: {
      get () {
        return this.appInfo.id ? this.appInfo : this.appInfoLocal
      },
      set (value) {
        this.appInfoLocal = value
        this.$emit('appChanged', value)
      }

    }
  }
}
</script>
